import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import PageTitle from './../Layout/PageTitle';
import ImgCarousel from './../Element/ImgCarousel';
import ClientCarousel from './../Element/ClientCarousel';

class ProjectDetails extends Component {
  render() {
    return (
      <>
        <Header />
        <div className='page-content bg-white'>
          <div className='dlab-bnr-inr overlay-primary'>
            <PageTitle
              motherMenu='Project Details'
              activeMenu='Project Details'
            />
          </div>
          <div className='content-block'>
            <div className='section-full content-inner-2'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-6 col-md-6'>
                    <h2 className='m-b10 m-t0'>Restaurant Point of Sale</h2>
                    <h5 className='m-b20'>Overview</h5>
                    <p className='m-b10'>
                      Lahore Bites wanted to develop a POS system which is a
                      modern, web-based platform that would improve efficiency
                      and streamline operations. After researching different
                      options, they decided to develop a custom POS system using
                      Ruby on Rails and ReactJS.
                    </p>
                    <a
                      href={'http://fg-app.pseudosquare.com'}
                      className='site-button radius-xl outline'
                      target={'_blank'}
                    >
                      Visit website <i className='fa fa-long-arrow-right'></i>
                    </a>
                  </div>
                  <div className='col-lg-6 col-md-6'>
                    <ImgCarousel />
                  </div>
                </div>
                {/* <div className='row m-b50'>
                  <div className='col-md-6 m-b20'>
                    <div className='p-a30 bg-gray'>
                      <h6>
                        Project Name:{' '}
                        <span className='m-l10 font-weight-300 text-gray-dark'>
                          {' '}
                          Business Accounting
                        </span>
                      </h6>
                      <h6>
                        Client:{' '}
                        <span className='m-l10 font-weight-300 text-gray-dark'>
                          {' '}
                          Company Name Inc.
                        </span>
                      </h6>
                      <h6>
                        Project Commencement Date:{' '}
                        <span className='m-l10 font-weight-300 text-gray-dark'>
                          {' '}
                          August 18, 2018
                        </span>
                      </h6>
                      <h6>
                        Priject Completion Date:{' '}
                        <span className='m-l10 font-weight-300 text-gray-dark'>
                          {' '}
                          August 18, 2018
                        </span>
                      </h6>
                      <h6>
                        Project url:{' '}
                        <span className='m-l10 font-weight-300 text-gray-dark'>
                          www.example.com
                        </span>
                      </h6>
                    </div>
                  </div>
                  <div className='col-md-6 align-self-center'>
                    <h5 className='text-black fw5 m-b10'>
                      Capitalize on low hanging fruit to identify a ballpark
                      value added activity to beta test. Override the digital
                      divide with additional clickthroughs from DevOps.
                      Nanotechnology immersion along the information highway
                      will close.
                    </h5>
                    <p className='m-b0'>
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout. The point of using Lorem Ipsum is that it
                      has a more-or-less normal distribution of letters, as
                      opposed to using 'Content here, content here.
                    </p>
                  </div>
                </div> */}
              </div>
              <div className='container'>
                <div className='row'>
                  <div class='cl-preview-section'>
                    <h3 id='development-process'>Development process</h3>
                  </div>
                  <div class='cl-preview-section'>
                    <p>
                      Planning and design: The development team worked with the
                      restaurant to identify their specific needs and
                      requirements for the POS system. They then created a
                      detailed plan and design for the system, including user
                      flows, database schema, and interface designs.
                    </p>
                  </div>
                  <div class='cl-preview-section'>
                    <p>
                      Back-end development: The team used Ruby on Rails to build
                      the back-end of the POS system, including the database,
                      APIs, and server-side logic. They also integrated the
                      system with the restaurant group’s existing financial and
                      inventory management systems.
                    </p>
                  </div>
                  <div class='cl-preview-section'>
                    <p>
                      Front-end development: The team used ReactJS to build the
                      front-end of the POS system, including the user interface,
                      interactions, and animations. They also designed a
                      responsive layout to ensure the system could be used on a
                      variety of devices.
                    </p>
                  </div>
                  <div class='cl-preview-section'>
                    <p>
                      Testing and deployment: The team conducted extensive
                      testing to ensure the POS system was reliable and
                      user-friendly. They also provided training to the
                      restaurant group’s staff on how to use the system. Once
                      everything was ready, they deployed the POS system to the
                      restaurants and began using it for daily operations.
                    </p>
                  </div>
                  <div class='cl-preview-section'>
                    <h3 id='results'>Results</h3>
                  </div>
                  <div class='cl-preview-section'>
                    <p>
                      The new POS system has significantly improved efficiency
                      and productivity at Lahore Bites. Orders and payments can
                      now be processed more quickly and accurately, and the
                      system’s analytics and reporting capabilities have given
                      the restaurant group valuable insights into their sales
                      and operations. The custom development process also
                      allowed the team to tailor the system to the restaurant
                      group’s specific needs and preferences. Overall, the POS
                      system has been a success and has received positive
                      feedback from both staff and customers.
                    </p>
                  </div>
                </div>
              </div>
              {/* <div className='container'>
                <div className='row m-b30'>
                  <div className='col-lg-4 col-md-12 align-self-center'>
                    <h5>Who We Are</h5>
                    <h2 className='font-weight-700'>
                      Leading The Way In Consulting Business For Over 25 Years
                    </h2>
                  </div>
                  <div className='col-lg-8 col-md-12'>
                    <div className='row'>
                      <div className='col-lg-4 col-md-4 col-sm-6'>
                        <div className='icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white'>
                          <svg
                            className='radial-progress'
                            data-percentage='90'
                            viewBox='0 0 80 80'
                          >
                            <circle
                              className='incomplete'
                              cx='40'
                              cy='40'
                              r='35'
                            ></circle>
                            <circle
                              className='complete'
                              cx='40'
                              cy='40'
                              r='35'
                              style={{ strokeDashoffset: '39.58406743523136' }}
                            ></circle>
                            <text
                              className='percentage'
                              x='50%'
                              y='57%'
                              transform='matrix(0, 1, -1, 0, 80, 0)'
                            >
                              90%
                            </text>
                          </svg>
                          <div className='icon-content'>
                            <h5 className='font-weight-500'>
                              Day Of Experience
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 col-sm-6'>
                        <div className='icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white'>
                          <svg
                            className='radial-progress'
                            data-percentage='49'
                            viewBox='0 0 80 80'
                          >
                            <circle
                              className='incomplete'
                              cx='40'
                              cy='40'
                              r='35'
                            ></circle>
                            <circle
                              className='complete'
                              cx='40'
                              cy='40'
                              r='35'
                              style={{ strokeDashoffset: '39.58406743523136' }}
                            ></circle>
                            <text
                              className='percentage'
                              x='50%'
                              y='57%'
                              transform='matrix(0, 1, -1, 0, 80, 0)'
                            >
                              90%
                            </text>
                          </svg>
                          <div className='icon-content'>
                            <h5 className='font-weight-500'>Awards Winning</h5>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 col-sm-6'>
                        <div className='icon-bx-wraper bx-style-1 p-t10 p-b30 p-lr20 m-b30 center br-col-b1 bg-white'>
                          <svg
                            className='radial-progress'
                            data-percentage='65'
                            viewBox='0 0 80 80'
                          >
                            <circle
                              className='incomplete'
                              cx='40'
                              cy='40'
                              r='35'
                            ></circle>
                            <circle
                              className='complete'
                              cx='40'
                              cy='40'
                              r='35'
                              style={{ strokeDashoffset: '39.58406743523136' }}
                            ></circle>
                            <text
                              className='percentage'
                              x='50%'
                              y='57%'
                              transform='matrix(0, 1, -1, 0, 80, 0)'
                            >
                              90%
                            </text>
                          </svg>
                          <div className='icon-content'>
                            <h5 className='font-weight-500'>Happy Clients</h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* <div className='container'>
                <ClientCarousel />
              </div> */}
            </div>
          </div>
          {/* <!-- contact area END --> */}
        </div>
        <Footer />
      </>
    );
  }
}
export default ProjectDetails;
