import React,{Fragment,  useState} from 'react';
import {Link} from 'react-router-dom';
import { TabContent, TabPane, } from 'reactstrap';
import classnames from 'classnames';

//Images
import pic5 from './../../images/about/pic5.jpg';
import pic6 from './../../images/about/pic6.jpg';
import pic7 from './../../images/about/pic7.jpg';
import pic8 from './../../images/about/pic8.jpg';

function Para(){
	return(
		<>
			<div className="abuot-box chose-us p-lr30">
			<p><b>Integrity - </b>Our reputation and relationships with our clients are more important to us than anything else. Thus we endeavor to be open and honest in our dealings.</p>
			<p><b>Customer Centered - </b>Our team prioritizes client satisfaction. Our success depends on long-term client relationships. We achieve this by listening  to their business concerns and providing personalized solutions.</p>
			<p><b>Transparency - </b>We value transparency, openness, and the free flow of ideas internally and externally. This encourages productive teamwork and unique ideas.</p>
			<p><b>Motivation - </b>We value transparency, openness, and the free flow of ideas internally and externally. This encourages productive teamwork and unique ideas.</p>
			</div>	
		</>
	)
}

const HomeTab = (props) => {
	const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
	  
	return(
		<Fragment>			
			<div className="container-fluid">
				<div className="dlab-tabs choseus-tabs">
					<ul className="nav row justify-content-center"  id="myTab" role="tablist">
						{/* <li>
							<a className= {classnames({ active : activeTab === '1' }) + ' className if avialble'}  onClick={() => { toggle('1'); }}	
							><span className="title-head">Cost Effectiveness</span></a>
						</li> */}
						<li>
							<Link to ={"#"} className= {classnames({ active : activeTab === '1'}) + ''} onClick={() => { toggle('1'); }}><span className="title-head">WHY OF YOUR BUSINESS</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '2'}) + ''} onClick={()=>{ toggle('2');}}><span className="title-head">WHAT WILL IT TAKE</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '3'}) + ''} onClick={()=>{ toggle('3');}}><span className="title-head">THEN: WE BUILD</span></Link>
						</li>
						<li>
							<Link to ={"#"}	className={classnames({active:activeTab === '4'}) + ''} onClick={()=>{ toggle('4');}}><span className="title-head">WE SEE AT WHAT WORKS AND APPLY IT</span></Link>
						</li>
					</ul>
				</div>	
			</div>
			<div className="container">
				<div className="tab-content chosesus-content">
					<div id="cost" className="tab-pane active">
						<TabContent activeTab={activeTab}>
							<TabPane tabId="1">
								<div className="row p-tb50">
									<div className="col-lg-6 m-b10 col-md-4 about-img " >
										<img src={pic5} alt="" />
									</div>
									<div className="col-lg-6 col-md-8">
										<div className="p-l30 d-flex justify-content-between" >
											{/* <h4 className="font-weight-300">Project Cost Effectiveness</h4> */}
											<div>
												<ul className="list-checked primary">
													<li><span>Discovery Call</span></li>
													<li><span>Design + technology audits</span></li>
													<li><span>Stakeholder convergence</span></li>
													<li><span>Benchmarking + SMART goal setting</span></li>
												</ul>
											</div>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="2">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic6} alt="" />
									</div>
									<div className="col-lg-6 col-md-8">
										<div className="p-l30 d-flex justify-content-between">
											{/* <h4 className="font-weight-300">Flexible Engagement Models</h4> */}
											<div>
												<ul className="list-checked primary">
												<li><span>Audience definition</span></li>
												<li><span>Impact mapping</span></li>
												<li><span>Digital strategy</span></li>
												<li><span>Scope definition</span></li>
												</ul>
											</div>
											<div>
												<ul className="list-checked primary">
													<li><span>Concept development</span></li>
													<li><span>Project management & Dev ops</span></li>
													<li><span>Team resourcing & onboarding</span></li>
												</ul>
											</div>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="3">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic7} alt="" />
									</div>
									<div className="col-lg-6 col-md-8">
										<div className="p-l30 d-flex justify-content-between">
											{/* <h4 className="font-weight-300">Technology Expertization</h4> */}
											<div>
												<ul className="list-checked primary">
												<li><span>Roadmapping & prioritizationn</span></li>
												<li><span>Design sprints</span></li>
												<li><span>Early validation & testing</span></li>
												<li><span>Proof of concept → MVP → Production</span></li>
												</ul>
											</div>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							<TabPane tabId="4">
								<div className="row p-tb50">
									<div className="col-lg-6 col-md-4 about-img">
										<img src={pic8} alt="" />
									</div>
									<div className="col-lg-6 col-md-8">
										<div className="p-l30 d-flex justify-content-between">
											{/* <h4 className="font-weight-300">Pre-Built Packages</h4> */}
											<div>
											<ul className="list-checked primary">
												<li><span>Training & stakeholder on-boarding</span></li>
												<li><span>Data analytics</span></li>
												<li><span>User experience research</span></li>
												<li><span>Optimization</span></li>
											</ul>
											</div>
										</div>
										<Para />
									</div>
								</div>
							</TabPane>
							
						</TabContent>
					</div>	
				</div>
			</div>	
		</Fragment>
	)
	
}	
export default HomeTab;