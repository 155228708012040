import React, { Component } from 'react';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';
import HomeOwlSlider from './../Element/HomeOwlSlider';
import HomeOwlSlider2 from './../Element/HomeOwlSlider2';
import ImgCarouselContent from './../Element/ImgCarouselContent';
import HomeTab from './../Element/HomeTab';
import Counter from './../Element/Counter';
import PortfolioCarousel from './../Element/PortfolioCarousel';
import TestimonialCarousel from './../Element/TestimonialCarousel';
import FormStyle from './../Element/FormStyle';

//Images
import pic4 from './../../images/about/pic4.jpg';
import bgimg from './../../images/background/bg1.jpg';
import bgimg2 from './../../images/background/bg-map.jpg';
import bgimg3 from './../../images/background/bg1.jpg';

class Homepage extends Component {
  componentDidMount() {}

  render() {
    return (
      <div className='page-wraper'>
        <Header />
        <div className='page-content bg-white'>
          {/*  Slider Banner */}
          <div className='owl-slider-banner main-slider'>
            <HomeOwlSlider />
            {/*  Service   */}
            <div className='service-box-slide'>
              <div className='container'>
                <div className='row'>
                  <div className='col-lg-12'>
                    <ImgCarouselContent />
                  </div>
                </div>
              </div>
            </div>
            {/*  Service End  */}
          </div>
          {/*  Slider Banner */}
          <div className='content-block'>
            {/* <!-- About Us --> */}
            <div className='section-full bg-white content-inner-1 about-us'>
              <div className='container'>
                <div className='row '>
                  <div className='col-lg-7 col-md-8'>
                    <div className='abuot-box row'>
                      <div className='col-lg-12'>
                        <h2 className='box-title m-tb0'>
                          About Us<span className='bg-primary'></span>
                        </h2>
                        <h4 className='text-gray-dark'>
                          We create unique experiences
                        </h4>
                        <p>
                          Pseudosquare is a full-service technology consulting,
                          design & development company. Since its inception,
                          Pseudosquare has aimed at standardizing the market by
                          providing a broad range of technology-related
                          services, including web design and web development,
                          branding, mobile application development and SEO
                          services. Our company has been built around the core
                          values of transparency, customer service,
                          productivity, and organization. The values were absent
                          from our competitors and the industry at large at the
                          time of our founding. After just a few years of work
                          from 2019, Pseudosquare has established itself as a
                          nationally recognized development powerhouse,
                          completing a wide range of projects in various
                          industries. Our management team has a wide range of
                          business experience in both startup and established
                          corporate organizations for more than just our
                          technical expertise.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='col-lg-5 col-md-4 about-img'>
                    <img src={pic4} data-tilt alt='' />
                  </div>
                </div>
              </div>
            </div>
            {/* <!-- About Us End --> */}
            {/* <!-- Our Services --> */}
            <div className='section-full content-inner-2'>
              <div className='container'>
                <div className='section-head text-black text-center'>
                  <h4 className='text-gray-dark m-b10'>Our Services</h4>
                  <h2 className='box-title m-tb0'>
                    Mobile & Web Design and Development Services{' '}
                    <span className='bg-primary'></span>
                  </h2>
                  <p>
                    We have a global team of designers, developers, and
                    marketers at Pseudosquare who use agile approaches for
                    scalable mobile and web development projects. We provide a
                    range of services that are suited to your company's
                    requirements.
                  </p>
                </div>
              </div>
              <div className='development-box'>
                <div className='container'>
                  <HomeOwlSlider2 />
                </div>
              </div>
            </div>
            {/* <!-- Our Services --> */}
            {/* <!-- Why Chose Us --> */}
            <div
              className='section-full content-inner-1 overlay-primary choseus-tabs  bg-img-fix'
              style={{ backgroundImage: 'url(' + bgimg + ')' }}
            >
              <div className='container'>
                <div className='section-head text-center'>
                  <h2 className='box-title m-tb0'>
                    Why Chose Us<span className='bg-primary'></span>
                  </h2>
                  <p>
                    In everything we do, we do it with thoughtfulness and care.
                    We'll ask you "why?" and possibly ask again. We'll delve as
                    far as you'll allow us to uncover the most economical,
                    impactful, and ideally meaningful manner to advance your
                    company.
                  </p>
                </div>
              </div>
              <HomeTab />
              <div className='choses-info'>
                <Counter />
              </div>
            </div>
            {/* <!-- Why Chose Us End --> */}
            {/* <!-- Our Portfolio --> */}
            <div className='section-full content-inner-1 mfp-gallery'>
              <div className='container-fluid'>
                <div className='section-head text-center'>
                  <h4 className='text-gray-dark m-b10'>Our Portfolio</h4>
                  <h2 className='box-title m-tb0'>
                    We work to innovate & are proud
                    <br /> of what we've created
                    <span className='bg-primary'></span>
                  </h2>
                  <h5>
                    YOU’LL LOVE TO SEE SOME OF OUR BEST PROJECTS. TAKE A LOOK!
                  </h5>
                </div>
                <PortfolioCarousel />
              </div>
            </div>
            {/* <!-- Our Portfolio END --> */}
            {/* <!-- Testimonials --> */}
            <div
              className='section-full content-inner'
              style={{
                backgroundImage: 'url(' + bgimg2 + ')',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <div className='container'>
                <div className='section-head text-center'>
                  <h2 className='box-title m-tb0'>
                    Our Testimonials<span className='bg-primary'></span>
                  </h2>
                  <p>
                    {' '}
                    We take great care of our valued customers and their
                    businesses{' '}
                  </p>
                </div>
                <div className='section-content m-b30 '>
                  <TestimonialCarousel />
                </div>
              </div>
            </div>
            {/* <!-- Testimonials END --> */}

            {/* <!-- Get in touch --> */}
            <div
              className='section-full overlay-primary-dark bg-img-fix'
              style={{ backgroundImage: 'url(' + bgimg3 + ')' }}
            >
              <FormStyle />
            </div>
            {/* <!-- Get in touch --> */}
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
export default Homepage;
