import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class Footer1 extends Component {
  render() {
    return (
      <>
        <footer className='site-footer'>
          <div className='footer-top'>
            <div className='container'>
              <div className='row'>
                <div className='col-5 col-lg-2 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_services border-0'>
                    <h5 className='m-b30'>Company</h5>
                    <ul>
                      <li>
                        <Link to={'./'}>Home </Link>
                      </li>
                      <li>
                        <Link to={'./about-us'}>About Us</Link>
                      </li>
                      <li>
                        <Link to={'./contact'}>Contact Us</Link>
                      </li>
                      <li>
                        <Link to={'./services'}>Our Services</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-7 col-lg-2 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_services border-0'>
                    <h5 className='m-b30'>Useful Link</h5>
                    <ul>
                      {/* <li><Link to={"#"}>Create Account</Link></li> */}
                      {/* <li><Link to={"#"}>Company Philosophy </Link></li> */}
                      {/* <li><Link to={"#"}>Corporate Culture</Link></li> */}
                      <li>
                        <Link to={'./blog'}>Blog</Link>
                      </li>
                      <li>
                        <Link to={'./portfolio'}>Portfolio</Link>
                      </li>
                      <li>
                        <Link to={'./project-management'}>
                          Project Management
                        </Link>
                      </li>
                      <li>
                        <Link to={'./project-details'}>Project Details</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 footer-col-4'>
                  <div className='widget widget_getintuch'>
                    <h5 className='m-b30 '>Contact us</h5>
                    <ul>
                      <li>
                        <i className='ti-location-pin'></i>
                        <strong>Address</strong> 240 R Block Johar Town, Lahore,
                        Pakistan{' '}
                      </li>
                      <li>
                        <i className='ti-mobile'></i>
                        <strong>Phone</strong>+92 332 639 1032
                      </li>
                      <li>
                        <i className='ti-email'></i>
                        <strong>Email</strong>
                        <a href={`mailto:hello@pseudosquare.com`}>
                          hello@pseudosquare.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-lg-4 col-md-6 col-sm-6 footer-col-4 '>
                  <div className='widget'>
                    <h5 className='m-b30'>
                      Subscribe To Our Newsletter
                    </h5>
                    <p className='m-b20'>
                      If you have any questions, please contact us so that we
                      can provide you with a satisfactory answer. To receive our
                      most recent products, sign up for our newsletter.
                    </p>
                    <div className='subscribe-form m-b20'>
                      <form
                        className='dzSubscribe'
                        action='script/mailchamp.php'
                        method='post'
                      >
                        <div className='dzSubscribeMsg'></div>
                        <div className='input-group'>
                          <input
                            name='dzEmail'
                            required='required'
                            className='form-control'
                            placeholder='Your Email Id'
                            type='email'
                          />
                          <span className='input-group-btn'>
                            <button
                              name='submit'
                              value='Submit'
                              type='submit'
                              className='site-button'
                            >
                              Subscribe
                            </button>
                          </span>
                        </div>
                      </form>
                    </div>
                    <ul className='list-inline m-a0'>
                      <li>
                        <a
                          href={'https://www.facebook.com/pseudosquare'}
                          className='site-button facebook circle mr-1'
                          target={'_blank'}
                        >
                          <i className='fa fa-facebook'></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href={'https://www.linkedin.com/company/pseudosquare'}
                          className='site-button linkedin circle mr-1'
                          target={'_blank'}
                        >
                          <i className='fa fa-linkedin'></i>
                        </a>
                      </li>
                      {/* <li><Link to={"#"} className="site-button instagram circle mr-1"><i className="fa fa-instagram"></i></Link></li> */}
                      <li>
                        <a
                          href={'https://www.twiter.com/pseudosquare1'}
                          className='site-button twitter circle mr-1'
                          target={'_blank'}
                        >
                          <i className='fa fa-twitter'></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer1;
