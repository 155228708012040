import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import Slider1 from './../../images/main-slider/slide1.jpg';
import Slider2 from './../../images/main-slider/slide2.jpg';
import Slider3 from './../../images/main-slider/slide3.jpg';

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <div className='owl-nav'>
      <div className='owl-next la la-angle-right' onClick={onClick} />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <div className='owl-nav'>
      <div
        className=' owl-prev la la-angle-left'
        onClick={onClick}
        style={{ zIndex: 1 }}
      />
    </div>
  );
}

class HomeOwlSlider extends Component {
  render() {
    var settings = {
      arrows: true,
			speed: 1000,
      dots: true,
      slidesToShow: 1,
      infinite: true,
      autoplay: true,
			autoplaySpeed: 8000,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };
    return (
      <Slider
        className='owl-slider owl-carousel owl-theme owl-none'
        {...settings}
      >
        <div className='item slide-item'>
          <div className='slide-item-img'>
            <img src={Slider1} className='w-100' alt='' />
          </div>
          <div className='slide-content overlay-primary'>
            <div className='slide-content-box container'>
              <div className='max-w600'>
                <h2 className='font-weight-400'>
                  We design and develop <br /> prodigious applications and
                  innovative products. <br />
                </h2>
                <p>
                  We are passionate of building momentum by building better
                  mobile Apps and responsive websites that assure your success.
                </p>
                <Link to={'#'} className='site-button m-r10 white button-lg'>
                  Get Started
                </Link>
                <Link
                  to={'#'}
                  className='site-button outline outline-2 button-lg'
                >
                  How It Work
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='item slide-item'>
          <div className='slide-item-img'>
            <img src={Slider3} className='w-100' alt='' />
          </div>
          <div className='slide-content overlay-primary'>
            <div className='slide-content-box container'>
              <div className='max-w600'>
                <h2 className='font-weight-400'>
                  Making websites & <br /> Apps that work for you! <br />
                </h2>
                <p>
                  For our clients, we help them grow, innovate and transform
                  through the design and development of digital products. Rather
                  than starting with a blank slate, we begin by listening,
                  learning, and understanding our clients.{' '}
                </p>
                <Link to={'#'} className='site-button m-r10 white button-lg'>
                  Get Started
                </Link>
                <Link
                  to={'#'}
                  className='site-button outline outline-2 button-lg'
                >
                  How It Work
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className='item slide-item'>
          <div className='slide-item-img'>
            <img src={Slider2} className='w-100' alt='' />
          </div>
          <div className='slide-content overlay-primary'>
            <div className='slide-content-box container'>
              <div className='max-w600'>
                <h2 className='font-weight-400'>
                  Web Develop Websites And Mobile Applications <br /> That Grow
                  Your Business.{' '}
                </h2>
                <p>
                  You and our team work together to set your goals, and then we
                  utilize our knowledge to discover the sweet spot between
                  feasible and meaningful.
                </p>
                <Link to={'#'} className='site-button m-r10 white button-lg'>
                  Get Started
                </Link>
                <Link
                  to={'#'}
                  className='site-button outline outline-2 button-lg'
                >
                  How It Work
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Slider>
    );
  }
}

export default HomeOwlSlider;
