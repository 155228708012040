import React, { Component } from 'react';
// import { Link } from 'react-router-dom';
import Header from './../Layout/Header1';
import Footer from './../Layout/Footer1';

import bgimg from './../../images/background/bg2.jpg';

class Contact extends Component {
  render() {
    return (
      <>
        <Header />
        <div className='full-section'>
          {/* <!-- inner page banner --> */}
          <div
            className='dlab-bnr-inr overlay-primary-dark contact-page'
            style={{ backgroundImage: 'url(' + bgimg + ')' }}
          >
            <div className='container'>
              <div className='row'>
                <div className='col-lg-4 col-xs-4'>
                  <div className='row text-white'>
                    <div className='col-lg-12 col-md-6 m-b30'>
                      <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                          <h5 className='dlab-tilte'>
                            <span className='icon-sm text-primary text-left'>
                              <i className='ti-location-pin'></i>
                            </span>
                            Company Address
                          </h5>
                          <p>240 R Block Johar Town, Lahore, Pakistan</p>
                          <h6 className='m-b15 font-weight-400'>
                            <i className='ti-alarm-clock'></i> Office Hours
                          </h6>
                          <p className='m-b0'>
                            Monday to Friday - 10:00 AM - 7:00 PM
                          </p>
                          <p>Saturday and Sunday - Closed</p>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-6 m-b30 '>
                      <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                          <h5 className='dlab-tilte'>
                            <span className='icon-sm text-primary text-left'>
                              <i className='ti-email'></i>
                            </span>
                            E-mail
                          </h5>
                          <p className='m-b0'>
                            <a href={`mailto:hello@pseudosquare.com`}>
                              hello@pseudosquare.com
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='col-lg-12 col-md-6 m-b30 '>
                      <div className='icon-bx-wraper bx-style-1 p-a20 radius-sm'>
                        <div className='icon-content'>
                          <h5 className='dlab-tilte'>
                            <span className='icon-sm text-primary text-left'>
                              <i className='ti-mobile'></i>
                            </span>
                            Phone Number
                          </h5>
                          <p>+92 332 639 1032</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8 col-xs-8'>
                  <form
                    className='inquiry-form Form'
                    action='http://localhost:3000/forms'
                  >
                    <div className='FormMsg'></div>
                    <h3 className='box-title m-t0 m-b10'>
                      Let's Convert Your Idea into Reality{' '}
                      <span className='bg-primary'></span>
                    </h3>
                    <p>
                      We enable you in realizing your ideas and turning them
                      into a revenue-generating machine. Please complete the
                      form below and we will contact you.
                    </p>
                    <div className='row'>
                      <div className='col-lg-6'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <span className='input-group-addon'>
                              <i className='ti-user text-primary'></i>
                            </span>
                            <input
                              name='name'
                              type='text'
                              required
                              className='form-control'
                              placeholder='Name'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-6'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <span className='input-group-addon'>
                              <i className='ti-mobile text-primary'></i>
                            </span>
                            <input
                              name='phone'
                              type='text'
                              required
                              className='form-control'
                              placeholder='Phone'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <span className='input-group-addon'>
                              <i className='ti-email text-primary'></i>
                            </span>
                            <input
                              name='email'
                              type='email'
                              className='form-control'
                              required
                              placeholder='Your Email Id'
                            />
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <div className='form-group'>
                          <div className='input-group'>
                            <span className='input-group-addon'>
                              <i className='ti-agenda text-primary'></i>
                            </span>
                            <textarea
                              name='message'
                              rows='4'
                              className='form-control'
                              required
                              placeholder='Tell us about your project or idea'
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div className='col-lg-12'>
                        <button
                          name='submit'
                          type='submit'
                          value='Submit'
                          className='site-button button-lg'
                        >
                          {' '}
                          <span>Get A Free Quote!</span>{' '}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* <!-- inner page banner END --> */}
        </div>

        <Footer />
      </>
    );
  }
}
export default Contact;
